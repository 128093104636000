import * as React from 'react';
import cls from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import styles from './index.module.sass';

interface Props {
  closeBtn?: boolean;
  closeClass?: string;
  containerClass?: string;
  open: boolean;
  overlayClass?: string;
  onClick: () => void;
}

export const Modal: React.FC<Props> = ({
  children,
  closeBtn,
  closeClass,
  containerClass,
  open,
  overlayClass,
  onClick,
}) => {
  const [ empty, setEmpty ] = React.useState<boolean>( !open );

  // ref used to access the current open value in async callback
  const openRef = React.useRef<boolean>( open );
  openRef.current = open;

  React.useEffect(() => {
    if ( open ) {
      setEmpty( false );

      // lock body
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
    } else {
      // unlock body
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo( 0, parseInt( scrollY || '0', 10 ) * -1 );

      setTimeout(() => {
        if ( !openRef.current ) {
          setEmpty( true );
        }
      }, 201 ); // empty children after modal closes
    }
  }, [ open ]);

  React.useEffect(() => {
    const handleKeyDown = ( event: React.KeyboardEvent ) => {
      // close on escape
      if ( event.keyCode === 27 ) {
        onClick();
      }
    };

    ( document as any ).addEventListener( 'keydown', handleKeyDown );
    return () => {
      ( document as any ).removeEventListener( 'keydown', handleKeyDown );
    };
  }, [ onClick ]);

  return (
    <div
      className={cls( styles.overlay, overlayClass, {
        [styles.open]: open,
      })}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      <div
        className={cls( styles.container, containerClass, {
          [styles.open]: open,
        })}
        onClick={( e ) => e.stopPropagation()}
        onKeyPress={( e ) => e.stopPropagation()}
        role="button"
        tabIndex={0}
      >
        {closeBtn && (
          <button
            aria-label="Close Content"
            className={cls( styles.close, closeClass, 'spec__close-modal' )}
            type="button"
            onClick={onClick}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        {!empty && children}
      </div>
    </div>
  );
};
